import { useMemo, useState } from 'react'
import { AiOutlineEye, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import Button from '@/components/Button'
import DotIndicator from '@/components/DotIndicator'
import { PAGES } from '@/utils/constants/pages'
import { OPENSANS_BOLD, ROBOTO_BOLD } from '@/utils/constants/themes'
import { type ProjectsDataType } from '@/utils/constants/types'

import { type ProjectSectionProps } from '../types'

const ProjectSection = ({ data }: ProjectSectionProps) => {
  const { t } = useTranslation(['common', 'home'])

  const [activeIndex, setActiveIndex] = useState(0)

  const selectedData: ProjectsDataType = useMemo(() => {
    return data[activeIndex]
  }, [data, activeIndex])

  const handleNext = () => {
    if (activeIndex < data.length) {
      setActiveIndex(activeIndex + 1)
    }
  }
  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    }
  }
  const handleDotClick = (i: number) => {
    setActiveIndex(i)
  }

  return (
    <div className="page-padding relative flex w-full flex-col items-center gap-y-16 bg-gradient-to-b from-white/0 to-primary/10 py-16 dark:to-primary/[0.15] lg:justify-center lg:gap-x-8 lg:gap-y-24 lg:py-32 xl:gap-x-36">
      <div className="w-full" data-aos="fade-down">
        <h2
          className={cn(
            'h2 text-center dark:text-primary',
            ROBOTO_BOLD.className,
          )}
        >
          {t('home:project_title')}
        </h2>
      </div>
      <div
        role="tabpanel"
        id={`panel-project-${String(activeIndex)}`}
        aria-labelledby={`tab-project-${String(activeIndex)}`}
        className="flex w-full flex-col items-center gap-y-8 lg:flex-row lg:items-start lg:justify-center lg:gap-x-8 xl:gap-x-12"
      >
        <div
          className="w-full max-w-xl lg:w-1/2"
          data-aos="zoom-in-up"
          data-aos-delay="300"
        >
          <Image
            src={selectedData.thumbnail}
            width={532}
            height={364}
            alt={selectedData?.title}
            className="shadow-primary-md dark:shadow-primary-md-dark w-full rounded-2xl"
          />
        </div>
        <div
          className="flex w-full max-w-xl flex-col gap-y-12 lg:w-1/2"
          data-aos="zoom-in-up"
          data-aos-delay="300"
        >
          <div className="flex flex-col gap-y-4">
            <h3 className={cn('h3', OPENSANS_BOLD.className)}>
              {selectedData?.title}
            </h3>
            <span className={cn('', ROBOTO_BOLD.className)}>
              {selectedData?.subtitle}
            </span>
            <p className="w-full">{selectedData?.desc}</p>
          </div>
          <div className="flex gap-x-4">
            <Button
              asLink
              linkProps={{
                href: `${PAGES.portfolio.path}/${String(selectedData?.id)}`,
              }}
              type="primary"
            >
              {t('common:view_detail')}
            </Button>
            <Button
              asLink
              linkProps={{
                href: selectedData?.demoUrl,
                target: '_blank',
                rel: 'nofollow',
              }}
              type="outline"
              icon={<AiOutlineEye />}
            >
              {t('common:live_view')}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center gap-y-4">
        <DotIndicator
          id="project"
          count={data.length}
          activeIndex={activeIndex}
          onClick={handleDotClick}
        />
        <div
          className="flex w-full items-center justify-center gap-x-2"
          data-aos="zoom-in-up"
          data-aos-delay="300"
        >
          <Button
            type={activeIndex === 0 ? 'outline' : 'primary'}
            buttonProps={{
              disabled: activeIndex === 0,
              onClick: handlePrev,
              'aria-label': String(t('home:prev_project')),
              className:
                'rounded-full !h-11 !w-11 !p-0 !gap-0 justify-center disabled:bg-transparent disabled:cursor-default',
            }}
          >
            <AiOutlineLeft />
          </Button>
          <Button
            type={activeIndex === data.length - 1 ? 'outline' : 'primary'}
            buttonProps={{
              disabled: activeIndex === data.length - 1,
              onClick: handleNext,
              'aria-label': String(t('home:next_project')),
              className:
                'rounded-full !h-11 !w-11 !p-0 !gap-0 justify-center disabled:bg-transparent disabled:cursor-default',
            }}
          >
            <AiOutlineRight />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProjectSection
